import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class TrainerUsersSurveyForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: {},
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { data } = this.props;
        const { formState } = this.state;

        this.setState({ 
            formState: { 
                ...formState,
            },
        });
    };

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    render() {
        const { location, history, data } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-users-survey-form"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    styleVersion={2}
                    header={(
                        <div className="user-survey-editor-header">
                            <div className="user-survey-editor-header-col col-left">
                                <h2 className="user-survey-editor-header-headline">
                                    Ankieta adepta {getUserFullName(data && data.user, 'nameSurname').label}
                                </h2>
                            </div>
                        </div>
                    )}
                    forms={[{
                        name: 'editor',
                        submitAction: data => data,
                        submitVisible: false,
                        data: {
                            ...formState,
                        },
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'datePicker',
                            name: 'createdAt',
                            label: 'Data utworzenia',
                            isVisible: Boolean(data.createdAt),
                            inputProps: {
                                disabled: true,
                                style: 'version-2',
                            },
                        }, {
                            type: 'input',
                            name: 'previousExpreience',
                            label: 'Poprzednie doświadczenia',
                            isVisible: Boolean(data.previousExpreience),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingTarget',
                            label: 'Cel treningowy',
                            isVisible: Boolean(data.trainingTarget),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'motionSystemPain',
                            label: 'Bóle układu ruchu',
                            isVisible: Boolean(data.motionSystemPain),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'contraindications',
                            label: 'Przeciwskazania lub choroby',
                            isVisible: Boolean(data.contraindications),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'userCity',
                            label: 'Miasto',
                            isVisible: Boolean(data.userCity),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'userAge',
                            label: 'Wiek',
                            isVisible: Boolean(data.userAge),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'trainedWithPersonalTrainer',
                            label: 'Czy trenował już z trenerem',
                            isVisible: Boolean(data.trainedWithPersonalTrainer),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'startDate',
                            label: 'Preferowana data startu',
                            isVisible: Boolean(data.startDate),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'nutrition',
                            label: 'Dieta',
                            isVisible: Boolean(data.nutrition),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'locations',
                            label: 'Lokalizacje',
                            isVisible: Boolean(data.locations),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'preferredDistrict',
                            label: 'Preferowana dzielnica',
                            isVisible: Boolean(data.preferredDistrict),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'physicalActivityLevel',
                            label: 'Aktywność fizyczna',
                            isVisible: Boolean(data.physicalActivityLevel),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'expectedSupport',
                            label: 'Spodziewane wsparcie',
                            isVisible: Boolean(data.expectedSupport),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'leadContraindications',
                            label: 'Główne przeciwskazania',
                            isVisible: Boolean(data.leadContraindications),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'sportCartSubscription',
                            label: 'Karty sportowe',
                            isVisible: Boolean(data.sportCartSubscription),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingDays',
                            label: 'Dni treningowe',
                            isVisible: Boolean(data.trainingDays),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingFrequency',
                            label: 'Ilość dni treningowych w tygodniu',
                            isVisible: Boolean(data.trainingFrequency),
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}